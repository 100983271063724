import React from "react";
import {useDeviceModelName} from "utils/useDeviceModelName";

interface IDeviceModelNameProps {
    model: string;
}

export const DeviceModelName: React.FC<IDeviceModelNameProps> = ({model}) => {
    const {modelName: deviceModelName} = useDeviceModelName(model);

    return (
        <>
            {deviceModelName}
        </>
    )
}
