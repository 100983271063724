import { EDeviceStatus, ELosantDeviceClass } from "contracts/EDeviceStatus";
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from "typedjson";
import { CompositeState, CompositeStateType } from "./compositeState";
import { CompositeStateItem } from "./compositeStateItem";
import { DeviceSerializer } from "./device";
import { IMappable } from "../IMappable";
import L from "leaflet";
import { VehicleBase } from "./vehicleBase";
import { DriverBase, DriverBaseSerializer } from "./driverBase";
import { DeviceBase, StoredDevice } from "./deviceBase";
import { LosantPagination } from "./losantPagination";
import { IOutdoorLocatable } from "../IOutdoorLocatable";
import dayjs from "dayjs";
import { IDeviceStatus } from "../IDeviceStatus";
import { EBatteryLevel } from "../EBatteryLevel";
import { EDeviceCommandTypes } from "../EDeviceCommandTypes";

@jsonObject()
export class Vehicle extends VehicleBase implements IOutdoorLocatable, IMappable {
    @jsonMember(DeviceBase, {
        deserializer: (value: any) => {
            let device: DeviceBase;
            try {
                if (typeof value === "string" || typeof value === "object") {
                    if (typeof value === "object" || (value.startsWith("{") && value.endsWith("}"))) {
                        device = DeviceSerializer.parse(value);
                    } else {
                        device = new DeviceBase();
                        device.id = value;
                    }
                }
            } catch (e) {
                console.error("Error Parsing Device for Vehicle", e);
            }

            return device;
        },
    })
    device?: StoredDevice;
    @jsonMember(DriverBase, {
        deserializer: (value: any) => {
            let driver: DriverBase;
            try {
                if (value && Object.keys(value).length > 0) {
                    driver = DriverBaseSerializer.parse(value);
                } else if (typeof value === "string") {
                    if (value.startsWith("{") && value.endsWith("}")) {
                        driver = DriverBaseSerializer.parse(value);
                    } else {
                        driver = new DriverBase();
                        driver.id = value;
                    }
                }
            } catch (e) {
                console.error("Error Parsing Driver for Vehicle", e);
            }

            return driver;
        },
    })
    driver?: Partial<DriverBase>;

    get deviceId(): string {
        return this.device?.id || "";
    }

    set deviceId(value: string) {
        this.device = new DeviceBase();
        this.device.id = value;
    }

    get driverId(): string {
        return this.driver?.id || "";
    }

    set driverId(value: string) {
        this.driver = new DriverBase();
        this.driver.id = value;
    }

    get uiHeight(): number {
        return this.additionalInfo || this.additionalInfo === "" ? 111 : 84;
    }

    get additionalInfo(): string {
        return this.device?.additionalInfo || "-";
    }

    get creationDate(): dayjs.Dayjs {
        return this.device?.creationDate;
    }

    get deviceClass(): ELosantDeviceClass {
        return this.device?.deviceClass;
    }

    get lastUpdated(): dayjs.Dayjs {
        return this.device?.lastUpdated;
    }

    get mobileId(): string {
        return this.device?.mobileId;
    }

    get status(): EDeviceStatus {
        return this.device?.status;
    }

    get statusInfo(): IDeviceStatus {
        return this.device?.statusInfo;
    }

    get batteryLevel(): EBatteryLevel {
        const isbarra = this.device?.getTagValue("device_model") == "barra-gps" ? true : false;
        if (this.device?.type == "Yabby Edge" || (this.device?.type == "ATrack" && !isbarra)) {
            return EBatteryLevel.NOT_CONNECTED;
        } else {
            let val = calculateBatteryInfo(this.device?.compositeState?.voltage_level?.value, isbarra);
            return val == undefined
                ? EBatteryLevel.NOT_CONNECTED
                : Number(val) > 90
                ? EBatteryLevel.FULL
                : Number(val) > 50
                ? EBatteryLevel.LOW
                : EBatteryLevel.WARNING;
        }
    }

    get batteryText(): string {
        const isbarra = this.device?.getTagValue("device_model") == "barra-gps" ? true : false;
        if (this.device?.type == "Yabby Edge" || this.device?.type == "ATrack") {
            return "";
        } else {
            let val = calculateBatteryInfo(this.device?.compositeState?.voltage_level?.value, isbarra);
            return `${val == undefined ? "" : val + " %"}`;
        }
    }
    get voltageLevel(): string {
        const isbarra = this.device?.getTagValue("device_model") == "barra-gps" ? true : false;
        return this.device?.type == "Yabby Edge" || (this.device?.type == "ATrack" && !isbarra)
            ? ""
            : this.device?.compositeState?.voltage_level?.value.toString() ?? "";
    }
    get externalBatteryLevel(): string {
        const isbarra = this.device?.getTagValue("device_model") == "barra-gps" ? true : false;

        if (this.device?.type == "Yabby Edge" || (this.device?.type == "ATrack" && !isbarra)) {
            return "";
        } else {
            let val = calculateBatteryInfo(this.device?.compositeState?.voltage_level?.value, isbarra);
            return `${val == undefined ? "" : val + " %"}`;
        }
    }

    get lotName(): string {
        return this.device?.compositeState?.lotName?.toString() ?? "";
    }

    get compositeState(): CompositeState {
        return this.device?.compositeState;
    }

    get isMoving(): boolean {
        return this.device?.isMoving;
    }

    get isOffline(): boolean {
        return this.device?.isOffline;
    }

    get latLng(): L.LatLng {
        return this.device?.latLng;
    }

    get isMappable(): boolean {
        return true;
    }

    getCommand(commandType: EDeviceCommandTypes): string | void {
        return this.device?.getCommand(commandType);
    }

    getTagValue(name: string): string | null {
        return this.device?.getTagValue(name);
    }

    getCompositeStateItem(index: CompositeStateType): CompositeStateItem {
        return this.device?.getCompositeStateItem(index);
    }

    getCompositeValue(index: CompositeStateType, suffix: string, fallback: string, alwaysShowSuffix: boolean): string {
        return this.device?.getCompositeValue(index, suffix, fallback, alwaysShowSuffix);
    }
}

@jsonObject()
export class VehiclePaginatedList extends LosantPagination {
    @jsonArrayMember(Vehicle)
    items: Vehicle[];
}

export type IVehicleSaveRequest = {
    data: Partial<Vehicle>;
};

export const VehicleSerializer = new TypedJSON(Vehicle);
function calculateBatteryInfo(voltage, isbarra) {
    try {
        // Define the default voltage ranges and corresponding percentages
        const voltageTableDefault = [
            { voltage: 12.7, percentage: 100 },
            { voltage: 12.5, percentage: 90 },
            { voltage: 12.42, percentage: 80 },
            { voltage: 12.32, percentage: 70 },
            { voltage: 12.2, percentage: 60 },
            { voltage: 12.06, percentage: 50 },
            { voltage: 11.9, percentage: 40 },
            { voltage: 11.79, percentage: 30 },
            { voltage: 11.58, percentage: 20 },
            { voltage: 11.31, percentage: 10 },
            { voltage: 10.5, percentage: 0 },
        ];

        // Define the voltage ranges for isdbarra
        const voltageTableIsbarra = [
            { voltage: 3.6, percentage: 100 },
            { voltage: 3.4, percentage: 90 },
            { voltage: 3.3, percentage: 80 },
            { voltage: 3.2, percentage: 70 },
            { voltage: 3.1, percentage: 60 },
            { voltage: 3.0, percentage: 50 },
            { voltage: 2.9, percentage: 40 },
            { voltage: 2.8, percentage: 30 },
            { voltage: 2.6, percentage: 20 },
            { voltage: 2.4, percentage: 10 },
            { voltage: 2.2, percentage: 0 },
        ];

        // Choose the correct voltage table
        const voltageTable = isbarra ? voltageTableIsbarra : voltageTableDefault;

        // Handle edge cases
        const maxVoltage = voltageTable[0].voltage;
        const minVoltage = voltageTable[voltageTable.length - 1].voltage;

        if (voltage >= maxVoltage) return 100;
        if (voltage <= minVoltage) return 0;

        // Find the two closest voltage entries
        for (let i = 0; i < voltageTable.length - 1; i++) {
            if (voltage <= voltageTable[i].voltage && voltage > voltageTable[i + 1].voltage) {
                const higherVoltage = voltageTable[i];
                const lowerVoltage = voltageTable[i + 1];

                // Interpolate between the two closest percentages
                const voltageRange = higherVoltage.voltage - lowerVoltage.voltage;
                const percentageRange = higherVoltage.percentage - lowerVoltage.percentage;
                const voltageAboveLower = voltage - lowerVoltage.voltage;

                const interpolatedPercentage =
                    lowerVoltage.percentage + (voltageAboveLower / voltageRange) * percentageRange;

                const roundedPercentage = Math.round(interpolatedPercentage);

                return roundedPercentage ?? "";
            }
        }
    } catch (e) {
        return "";
    }
}

