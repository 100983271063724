import React, { useState } from "react";
import "./calander.css";
import { ITime } from "assets/icons/i-time";
import { IDistance } from "assets/icons/i-distance";

const MaintenanceCalender = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({ day: "", title: "", type: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [taskModalOpen, setTaskModalOpen] = useState(false);
    const [newTask, setNewTask] = useState({ title: "", distance: "", time: "", type: "", day: null });

    const [tasks, setTasks] = useState([]);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate();
    };

    const getStartDayOfMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    };

    const handleChange = (event) => {
        const [month, year] = event.target.value.split("-");
        setCurrentDate(new Date(year, month, 1));
    };

    const handlePrevMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    const handleAddEvent = () => {
        if (newEvent.day && newEvent.title && newEvent.type) {
            setEvents([...events, { ...newEvent, day: parseInt(newEvent.day, 10) }]);
            setNewEvent({ day: "", title: "", type: "" });
            setIsModalOpen(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };

    const daysInMonth = getDaysInMonth(currentDate);
    const startDay = getStartDayOfMonth(currentDate);

    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(currentDate.getMonth() - 1);
    const daysInPrevMonth = getDaysInMonth(prevMonth);

    const daysArray = [...Array(daysInMonth).keys()].map((i) => i + 1);

    const prevMonthDays = Array.from({ length: startDay }, (_, i) => daysInPrevMonth - startDay + i + 1);

    const totalDaysShown = prevMonthDays.length + daysArray.length;
    const nextMonthDaysCount = 35 - totalDaysShown;
    const nextMonthDays = Array.from({ length: nextMonthDaysCount }, (_, i) => i + 1);

    const handleDayClick = (day) => {
        setSelectedDay(day);
        setNewTask({ ...newTask, day });
        setTaskModalOpen(true);
    };

    const filteredTasks = tasks.filter((task) => task.day === selectedDay);

    const handleAddTask = () => {
        if (newTask.title && newTask.distance && newTask.time && newTask.type && newTask.day) {
            setTasks([...tasks, newTask]);
            setNewTask({ title: "", distance: "", time: "", type: "", day: null });
            setTaskModalOpen(false);
        } else {
            alert("Please fill all fields!");
        }
    };

    const handleRemoveTask = (taskToRemove) => {
        const updatedTasks = tasks.filter((task) => task !== taskToRemove);
        setTasks(updatedTasks);
    };

    const getTasksForDay = (day) => {
        return tasks.filter((task) => task.day === day);
    };

    return (
        <div className="main-calendar">
            <div className="calendar">
                <div className="header">
                    <div className="dropdown-container">
                        <div style={{ display: "flex" }}>
                            <select
                                value={`${currentDate.getMonth()}-${currentDate.getFullYear()}`}
                                onChange={handleChange}
                            >
                                {Array.from({ length: 12 }, (_, index) => (
                                    <option key={index} value={`${index}-${currentDate.getFullYear()}`}>
                                        {new Date(0, index).toLocaleString("default", { month: "long" })}{" "}
                                        {currentDate.getFullYear()}
                                    </option>
                                ))}
                            </select>
                            <div className="arrow-buttons">
                                <button onClick={handlePrevMonth} className="arrow-btn">
                                    ←
                                </button>
                                <button onClick={handleNextMonth} className="arrow-btn">
                                    →
                                </button>
                            </div>
                        </div>
                        <div className="labels">
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <div className="label completed"></div>Completed
                            </div>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <div className="label overdue"></div>Overdue
                            </div>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <div className="label scheduled"></div>Scheduled
                            </div>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <div className="label other"></div>Other
                            </div>
                        </div>
                    </div>
                </div>

                <div className="day-names">
                    {dayNames.map((dayName, index) => (
                        <div key={index} className="day-name">
                            {dayName}
                        </div>
                    ))}
                </div>

                <div className="grid">
                    {prevMonthDays.map((day, index) => (
                        <div key={`prev-${index}`} className="day prev-month-day">
                            <div className="day-number">{day}</div>
                        </div>
                    ))}
                    {daysArray.map((day) => (
                        <div key={day} className="day" onClick={() => handleDayClick(day)}>
                            <div className="day-number">{day}</div>

                            <ul className="event-list">
                                {getTasksForDay(day).map((event, index) => (
                                    <li key={index} className={`event ${event.type}`}>
                                        {event.title}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    {nextMonthDays.map((day, index) => (
                        <div key={`next-${index}`} className="day next-month-day">
                            <div className="day-number">{day}</div>
                        </div>
                    ))}
                </div>

                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal">
                            <h2>Add Event</h2>
                            <input
                                type="text"
                                name="label"
                                value={newEvent.title}
                                placeholder="Event Label"
                                onChange={handleInputChange}
                            />
                            <select name="type" value={newEvent.type} onChange={handleInputChange}>
                                <option value="">Select Type</option>
                                <option value="completed">Completed</option>
                                <option value="overdue">Overdue</option>
                                <option value="scheduled">Scheduled</option>
                                <option value="other">Other</option>
                            </select>
                            <div className="modal-buttons">
                                <button onClick={handleAddEvent}>Add</button>
                                <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {selectedDay && (
                <div className="task-list">
                    <h2 style={{ height: "30px", marginTop: "5px" }}>
                        {dayNames[new Date(currentDate.getFullYear(), currentDate.getMonth(), selectedDay).getDay()]},{" "}
                        {currentDate.toLocaleString("default", { month: "long" })} {selectedDay}
                    </h2>
                    {filteredTasks.length > 0 ? (
                        filteredTasks.map((task, index) => (
                            <div key={index} className={`task-item ${task.type}`}>
                                <div className="task-info">
                                    <h3>{task.title}</h3>
                                    <div className="task-details">
                                        <span className="task-distance">
                                            <IDistance style={{ marginRight: "1px", marginBottom: "4px" }} />{" "}
                                            {task.distance} mi
                                        </span>
                                        <span className="task-time">
                                            <ITime style={{ marginRight: "1px", marginBottom: "4px" }} /> {task.time} h
                                        </span>
                                    </div>
                                </div>
                                <div className="task-action">
                                    <button className="task-button">
                                        <span>&#8594;</span>
                                    </button>
                                    <button className="delete-btn" onClick={() => handleRemoveTask(task)}>
                                        ✕
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No tasks for this day</p>
                    )}
                </div>
            )}

            {taskModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>
                            Add Task for {currentDate.toLocaleString("default", { month: "long" })} {selectedDay}
                        </h2>
                        <input
                            type="text"
                            placeholder="Task Title"
                            value={newTask.title}
                            onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="Distance (mi)"
                            value={newTask.distance}
                            onChange={(e) => setNewTask({ ...newTask, distance: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="Time (h)"
                            value={newTask.time}
                            onChange={(e) => setNewTask({ ...newTask, time: e.target.value })}
                        />
                        <select
                            name="type"
                            value={newTask.type}
                            onChange={(e) => setNewTask({ ...newTask, type: e.target.value })}
                        >
                            <option value="">Select Type</option>
                            <option value="completed">Completed</option>
                            <option value="overdue">Overdue</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="other">Other</option>
                        </select>
                        <div className="modal-buttons">
                            <button onClick={handleAddTask}>Add Task</button>
                            <button onClick={() => setTaskModalOpen(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MaintenanceCalender;

