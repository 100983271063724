import React, { useEffect, useMemo, useState } from "react";
import { EResource } from "../../contracts/EResource";
import { useGetNotificationListQuery } from "../../redux/services/losant/notifications";
import useTablePagination from "../../utils/useTablePagination";
import { IVehicle } from "assets/icons/i-vehicle";
import { IDistance } from "assets/icons/i-distance";
import { ITime } from "assets/icons/i-time";
import { IDate } from "assets/icons/i-date";
import MaintenanceList from "components/maintenance-list";

export const Maintenance: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState("overdue");
    const { pagination, searchTerm, handleTableChange, handleSearch, sorting } = useTablePagination(
        {},
        { order: "descend", field: "createdAt" }
    );

    const { isLoading, data: tableResponse } = useGetNotificationListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        fieldValue: "deviceName", // Perhaps we can change it to better
        searchValue: searchTerm,
        sort: sorting.order,
    });

    const columns = useMemo(
        () => [
            {
                title: "Asset",
                key: "asset",
                dataIndex: "Asset",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <div>
                                    <IVehicle style={{ marginRight: 5 }} />
                                    <span>Vehicle: {record.asset}</span>
                                </div>
                                <div>
                                    <IDistance style={{ marginRight: 5 }} />
                                    <span>Distance: {record.distance} mi</span>
                                </div>
                                <div>
                                    <ITime style={{ marginRight: 5 }} />
                                    <span>Time: {record.time}h</span>
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Maintenance Scheduled",
                key: "maintenanceScheduled",
                dataIndex: "maintenanceScheduled",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <IDate style={{ marginRight: 5 }} />
                                <span>Date: {record.date}</span>
                            </div>
                            <div>
                                <IDistance style={{ marginRight: 5 }} />
                                <span>Distance: {record.distance} mi</span>
                            </div>
                            <div>
                                <ITime style={{ marginRight: 5 }} />
                                <span>Time: {record.time}h</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Service Depot",
                key: "serviceDepot",
                dataIndex: "serviceDepot",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>Task: {record.task}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Send Alert",
                key: "sendAlert",
                dataIndex: "sendAlert",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <IDate style={{ marginRight: 5 }} />
                                <span>Days : {record.days}</span>
                            </div>
                            <div>
                                <IDistance style={{ marginRight: 5 }} />
                                <span>Distance : {record.send_distance} mi</span>
                            </div>
                            <div>
                                <ITime style={{ marginRight: 5 }} />
                                <span>Time: {record.send_time}h</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Notifications",
                key: "notifications",
                dataIndex: "notifications",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>Email : {record.email}</span>
                            </div>
                            <div>
                                <span>SMS : {record.sms}</span>
                            </div>
                        </>
                    );
                },
            },
        ],
        []
    );

    const data = [
        {
            key: "1",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "2",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "3",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "4",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
    ];
    const data2 = [
        {
            key: "1",
            asset: "BMW2",
            distance: "2.32",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "2",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "3",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
        {
            key: "4",
            asset: "BMW",
            distance: "2.3",
            time: "4",
            date: "Dec 03, 2021",
            task: "Oil Change",
            days: "7 dayes",
            send_distance: "2.3",
            send_time: "4",
            email: "true",
            sms: "true",
        },
    ];

    const columns3 = useMemo(
        () => [
            {
                title: "Asset",
                key: "asset",
                dataIndex: "Asset",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.asset}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Service Date",
                key: "serviceDate",
                dataIndex: "serviceDate",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.serviceDate}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Service Depot",
                key: "serviceDepot",
                dataIndex: "serviceDepot",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.serviceDepot}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Maintenance",
                key: "maintenance",
                dataIndex: "maintenance",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.maintenance}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Odometer",
                key: "odometer",
                dataIndex: "odometer",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.odometer}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Hours",
                key: "hours",
                dataIndex: "hours",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.hours}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Cost",
                key: "cost",
                dataIndex: "cost",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.cost}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Logged By",
                key: "loggedBy",
                dataIndex: "loggedBy",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.loggedBy}</span>
                            </div>
                        </>
                    );
                },
            },
        ],
        []
    );

    const data3 = [
        {
            key: "1",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "2",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "3",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "4",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
    ];

    useEffect(() => {
        console.log("isModalVisible", isModalVisible);
    }, [isModalVisible]);

    return (
        <div style={{ margin: "50px" }}>
            <MaintenanceList
                title="Maintenance"
                resource={EResource.MAINTENANCE}
                onSearch={handleSearch}
                onChange={handleTableChange}
                pagination={pagination}
                isLoading={isLoading}
                dataSource={isModalVisible === "overdue" ? data : isModalVisible === "schedule" ? data2 : []}
                columns={isModalVisible === "overdue" ? columns : isModalVisible === "schedule" ? columns : []}
                totalCount={tableResponse?.totalCount}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        </div>
    );
};

export default Maintenance;

