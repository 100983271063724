import {convertStringToTemperatureUnit, ETemperatureUnit} from "contracts/ETemperatureUnit";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {GroupTags, IGroupTags} from "./groupTags";
import {DeviceTag} from "./deviceTag";
import {AMERICA_NEW_YORK} from "../AvailabeTimezones";

interface IExperienceGroup {
    deviceTags: DeviceTag[];
    experienceUserIds: string[];
    groupTags: IGroupTags;
    name: string;
    parentId: string;
}

@jsonObject
export class ExperienceGroup {
    deviceIds: string[];

    applicationId: string;
    creationDate: string;
    lastUpdated: string;
    experienceGroupId: string;
    experienceEndpointIds: string[];
    experienceUserIds: string[];


    @jsonArrayMember(() => DeviceTag)
    deviceTags: DeviceTag[];

    @jsonMember(String)
    id: string;

    @jsonMember(String)
    name: string;

    @jsonMember(String)
    parentId: string;

    @jsonMember(GroupTags)
    groupTags: GroupTags;

    get timezone(): string {
        return this.groupTags?.timeZone || AMERICA_NEW_YORK;
    }

    get temperatureUnit(): ETemperatureUnit {
        return convertStringToTemperatureUnit(this.groupTags?.temperatureUnit) || ETemperatureUnit.Fahrenheit;
    }

    toJSON(): IExperienceGroup {
        return {
            deviceTags: this.deviceTags,
            experienceUserIds: this.experienceUserIds,
            groupTags: this.groupTags.toJSON(),
            name: this.name,
            parentId: this.parentId,
        }
    }
}


export const ExperienceGroupSerializer = new TypedJSON(ExperienceGroup);
