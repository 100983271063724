import {ProductConfigResponse} from "contracts/ProductConfigResponse";
import {useFetchProductConfigsQuery} from "redux/services/losant/sensors";

interface IDeviceModelNameHookResponse {
    modelName: string;
    error: any;
    isLoading: boolean;
    data: ProductConfigResponse;
}


export function useDeviceModelName(model: string) : IDeviceModelNameHookResponse {
    const {data, error, isLoading} = useFetchProductConfigsQuery();

    return {
        modelName: (!isLoading)
            ? data?.deviceModels.find((modelConfig) => (modelConfig.id === model))?.label
            : '-',
        error,
        isLoading,
        data
    }
}
