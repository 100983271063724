import React, { useMemo } from "react";
import { EResource } from "../../contracts/EResource";
import { useGetNotificationListQuery } from "../../redux/services/losant/notifications";
import useTablePagination from "../../utils/useTablePagination";
import MaintenanceList from "components/maintenance-list";

export const MaintenanceLog: React.FC = () => {
    const { pagination, searchTerm, handleTableChange, handleSearch, sorting } = useTablePagination(
        {},
        { order: "descend", field: "createdAt" }
    );

    const { isLoading, data: tableResponse } = useGetNotificationListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        fieldValue: "deviceName", // Perhaps we can change it to better
        searchValue: searchTerm,
        sort: sorting.order,
    });

    const columns = useMemo(
        () => [
            {
                title: "Asset",
                key: "asset",
                dataIndex: "Asset",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.asset}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Service Date",
                key: "serviceDate",
                dataIndex: "serviceDate",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.serviceDate}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Service Depot",
                key: "serviceDepot",
                dataIndex: "serviceDepot",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.serviceDepot}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Maintenance",
                key: "maintenance",
                dataIndex: "maintenance",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.maintenance}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Odometer",
                key: "odometer",
                dataIndex: "odometer",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.odometer}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Hours",
                key: "hours",
                dataIndex: "hours",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.hours}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Cost",
                key: "cost",
                dataIndex: "cost",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.cost}</span>
                            </div>
                        </>
                    );
                },
            },
            {
                title: "Logged By",
                key: "loggedBy",
                dataIndex: "loggedBy",
                render: (item, record) => {
                    return (
                        <>
                            <div>
                                <span>{record.loggedBy}</span>
                            </div>
                        </>
                    );
                },
            },
        ],
        []
    );

    const data = [
        {
            key: "1",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "2",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "3",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
        {
            key: "4",
            asset: "Ven - v114",
            serviceDate: "Aug 3,2021",
            serviceDepot: "Han’s Auto Repair",
            maintenance: "Oil Change",
            odometer: "125486552",
            hours: "356",
            cost: "$ 59.09",
            loggedBy: "Mark",
        },
    ];

    return (
        <>
            <div style={{ margin: "50px" }}>
                <MaintenanceList
                    title="Maintenance Log"
                    resource={EResource.MAINTENANCELOG}
                    onSearch={handleSearch}
                    onChange={handleTableChange}
                    pagination={pagination}
                    isLoading={isLoading}
                    dataSource={data}
                    columns={columns}
                    totalCount={tableResponse?.totalCount}
                />
            </div>
        </>
    );
};

export default MaintenanceLog;

