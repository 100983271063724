import {jsonMember, jsonObject, TypedJSON} from "typedjson";

export interface IGroupTags {
    accountId: string;
    parentId: string;
    accountTypeId: string;
    timeZone: string;
    accountType: string;
    contactPerson: string;
    email: string;
    mobileNumber: string;
    businessNumber: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    isSuper: string;
    isBusiness: string;
    isPersonal: string;
    isSuspended: string;
    actualLocatePoints: string;
    includedLocatePoints: string;
}

@jsonObject()
export class GroupTags {
    @jsonMember(String)
    accountId: string;

    @jsonMember(String)
    parentId: string;

    @jsonMember(String)
    accountTypeId: string;

    @jsonMember(String)
    timeZone: string;

    @jsonMember(String)
    accountType: string;

    @jsonMember(String)
    contactPerson: string;

    @jsonMember(String)
    email: string;

    @jsonMember(String)
    mobileNumber: string;

    @jsonMember(String)
    businessNumber: string;

    @jsonMember(String)
    streetAddress: string;

    @jsonMember(String)
    city: string;

    @jsonMember(String)
    state: string;

    @jsonMember(String)
    postalCode: string;

    @jsonMember(String)
    country: string;

    @jsonMember(Boolean, {
        deserializer: (value: string) => {
            return value === "true";
        }
    })
    isSuper: boolean;

    @jsonMember(Boolean, {
        deserializer: (value: string) => {
            return value === "true";
        }
    })
    isBusiness: boolean;

    @jsonMember(Boolean, {
        deserializer: (value: string) => {
            return value === "true";
        }
    })
    isPersonal: boolean;


    @jsonMember(Boolean, {
        deserializer: (value: string) => {
            return value === "true";
        }
    })
    isSuspended: boolean;


    @jsonMember(Boolean, {
        deserializer: (value: string) => {
            return value === "true";
        }
    })
    isIndoorTrackingEnabled: boolean;


    @jsonMember(Number, {
        deserializer: (value: string) => {
            const data = parseInt(value, 10);
            try {
                return (isNaN(data) || !data) ? 0 : data;
            } catch (e) {
                return 0;
            }
        }
    })
    actualLocatePoints: number;

    @jsonMember(Number, {
        deserializer: (value: string) => {
            const data = parseInt(value, 10);
            try {
                return (isNaN(data) || !data) ? 0 : data;
            } catch (e) {
                return 0;
            }
        }
    })
    includedLocatePoints: number;


    get percentageLeft(): number {
        return parseFloat(((this.actualLocatePoints / this.includedLocatePoints) * 100).toFixed(1));
    }

    get temperatureUnit(): string {
        return "fahrenheit"; // MAYBE: Get this from the account
    }


    get language(): string {
        return "English"; // MAYBE: Get this from the account
    }

    toJSON(): IGroupTags {
        return {
            contactPerson: this.contactPerson,
            email: this.email,
            mobileNumber: this.mobileNumber,
            businessNumber: this.businessNumber,
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            postalCode: this.postalCode,
            country: this.country,
            accountId: this.accountId,
            parentId: this.parentId,
            accountTypeId: this.accountTypeId,
            timeZone: this.timeZone,
            accountType: this.accountType,
            isSuper: this.isSuper ? 'true' : 'false',
            isBusiness: this.isBusiness ? 'true' : 'false',
            isPersonal: this.isPersonal ? 'true' : 'false',
            isSuspended: this.isSuspended ? 'true' : 'false',
            actualLocatePoints: this.actualLocatePoints.toString(),
            includedLocatePoints: this.includedLocatePoints.toString(),
        }
    }
}

export const GroupTagsSerializer = new TypedJSON(GroupTags);
