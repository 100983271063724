export const TAG_DEVICE = "device" as const;
export const TAG_DEVICE_SENSOR = "device-sensor" as const;
export const TAG_PRODUCT_CONFIGS = 'product-configs' as const;
export const TAG_VEHICLE = "vehicle" as const;
export const TAG_DRIVER = "driver" as const;
export const TAG_INDOOR_ASSET = "indoorAsset" as const;
export const TAG_DEVICE_SETTINGS = 'deviceSettings' as const;
export const TAG_GEOFENCE = "geofence" as const;
export const TAG_GEOFENCE_SLOT_CONFIG = "geofenceSlotConfig" as const;
export const TAG_INDOOR_LAYOUT = "indoorLayout" as const;
export const TAG_GEOFENCE_KEY = "geofenceKey" as const;
export const TAG_NOTIFICATION = "notification" as const;
export const LOSANT_API_REDUCER_KEY = "losant" as const;
export const TAG_USER = "user" as const;
export const TAG_ALERT = "alert" as const;
export const TAG_EXPERIENCE_GROUP = 'EXPERIENCE_GROUP' as const;
export const TAG_ROLE = "Roles" as const;
export const TAG_RECIPIENT = "Recipients" as const;
export const TAG_GROUP = "Groups" as const;
export const TAG_MESSAGE = "Messages" as const;
export const TAG_HISTORICAL_REPORT = "HistoricalReport" as const;
export const TRIPS_REDUCER_KEY = "trips" as const
