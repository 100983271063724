import React, {Suspense} from "react";
import {Helmet} from "react-helmet";
import {Route, Routes} from "react-router-dom";
import {Spin} from "antd";
import HolotrakSettingsLayout from "./HolotrakSettingsLayout";
import "./settings.scss";

const SettingRoleManagement = React.lazy(() => import("./role"));
const SettingRecipientManagement = React.lazy(() => import("./recipient"));
const GroupManagement = React.lazy(() => import("./group"));
const SettingUserManagement = React.lazy(() => import("./user"));
const SettingAlertManagement = React.lazy(() => import("./alert"));
const ActivityLog = React.lazy(() => import("./activityLog"));
const SettingDevice = React.lazy(() => import("./device"));
const GeofenceSettings = React.lazy(() => import("./geofence"));

const SettingGeneralForm = React.lazy(() => import('./general/SettingGeneralForm'));


export const Settings: React.FC = () => {
    return (
        <>
            <Helmet titleTemplate="%s | Settings | Holotrak">
                <meta name="description" content="Holotrak settings"/>
            </Helmet>

            <Suspense fallback={<Spin spinning={true}/>}>
                <Routes>
                    <Route path='/' element={<HolotrakSettingsLayout/>}>
                        <Route path='/general' index element={<SettingGeneralForm/>}/>
                        <Route path='/roles' element={<SettingRoleManagement/>}/>
                        <Route path='/recipients' element={<SettingRecipientManagement/>}/>
                        <Route path='/groups' element={<GroupManagement/>}/>
                        <Route path='/devices/:id?' element={<SettingDevice/>}/>
                        <Route path='/users' element={<SettingUserManagement/>}/>
                        <Route path='/alerts' element={<SettingAlertManagement isUsingSensors={false}/>}/>
                        <Route path='/sensor-alerts' element={<SettingAlertManagement isUsingSensors={true}/>}/>
                        <Route path='/activity' element={<ActivityLog/>}/>
                        <Route path='/geofence' element={<GeofenceSettings/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
};

export default Settings;
