import {createSelector} from "@reduxjs/toolkit";
import {ISensorSaveRequest, SensorBase} from "contracts/holotrak/sensorBase";
import {ProductConfigResponse} from "contracts/ProductConfigResponse";
import {DriverBase} from "../../../contracts/holotrak/driverBase";
import {losantApi} from "./index";
import {selectDriverFilters} from "../../slices/app";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {Driver, DriverPaginatedList, DriverSerializer, IDriverSaveRequest} from "../../../contracts/holotrak/driver";
import {TAG_DRIVER, TAG_DEVICE_SENSOR, TAG_VEHICLE, TAG_PRODUCT_CONFIGS} from "../../../contracts/reduxResourceTags";
import {selectAllDevices} from "../express";
import {DeviceSerializer} from "../../../contracts/holotrak/device";

const sensorsApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({

        updateSensor: builder.mutation<SensorBase, ISensorSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [{
                    type: TAG_DEVICE_SENSOR,
                    id: "LIST",
                }, {
                    type: TAG_DEVICE_SENSOR,
                    id: arg.data.id,
                }];
            },
            query: (sensorBody) => {
                let sensorData = {
                    ...sensorBody.data,
                };

                return {
                    url: `/device-sensors/${sensorBody.data.id}`,
                    method: "put",
                    body: {
                        data: sensorData
                    },
                };
            },
        }),

        createSensor: builder.mutation<SensorBase, ISensorSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [{
                    type: TAG_DEVICE_SENSOR,
                    id: "LIST",
                }, {
                    type: TAG_DEVICE_SENSOR,
                    id: arg.data.id,
                }];
            },
            query: (sensorBody) => {
                return {
                    url: `/device-sensors`,
                    method: "post",
                    body: {
                        data: {
                            ...sensorBody.data,
                        }
                    },
                };
            },
        }),


        deleteSensor: builder.mutation<void, string>({
            invalidatesTags: (result, error, sensorId) => {
                return [{
                    type: TAG_DEVICE_SENSOR,
                    id: "LIST",
                }, {
                    type: TAG_DEVICE_SENSOR,
                    id: sensorId,
                }];
            },
            query: (sensorId) => {
                return {
                    url: `/device-sensors/${sensorId}`,
                    method: "delete",
                };
            }
        }),

        fetchProductConfigs: builder.query<ProductConfigResponse, void>({
            query: () => ({
                url: `/admin/product-configs`,
                method: "get",
            }),
            providesTags: (result) => [{
                type: TAG_PRODUCT_CONFIGS,
                id: "LIST",
            }]
        }),

    })
});


export const {
    useCreateSensorMutation,
    useDeleteSensorMutation,
    useUpdateSensorMutation,
    useFetchProductConfigsQuery
} = sensorsApi;
