import {Typography} from "antd";

import React from 'react';
import {Link} from "react-router-dom";
import {selectDeviceById} from "redux/services/express";
import {useTypedSelector} from "redux/store";

const {Text} = Typography;

interface IQuickDeviceInfoProps {
    id: string;
}

export const QuickDeviceInfo: React.FC<IQuickDeviceInfoProps> = ({id}) => {
    const device = useTypedSelector(state => selectDeviceById(state, id));

    return device?.name ? (
        <Link to={`/devices/${id}`}>
            <Text>{device?.name}</Text>
        </Link>
    ) : (
        <>
            <Text>-</Text>
        </>
    );
}
